<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button fill="clear" class="close-button" (click)="dismiss()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <div class="modal-title">
    <h3>{{ id ? 'Edit Tag' : 'Add User Tag' }}</h3>
  </div>
</ion-header>

<ion-content>
  <ion-card style="margin: 15px">
    <ion-card-content>
      <ion-label>
        <strong>Name</strong>
      </ion-label>
      <ion-input #nameInput placeholder="e.g. Super users" [(ngModel)]="name" class="input-field"> </ion-input>
    </ion-card-content>
  </ion-card>
</ion-content>

<ion-footer class="footer">
  <ion-button (click)="submit()" [disabled]="isLoading">
    Save tag
    <ion-spinner name="crescent" [hidden]="!isLoading" style="margin-left: 10px"></ion-spinner>
  </ion-button>
</ion-footer>
